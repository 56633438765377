/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.pricecolor{
  width: 87%;
  text-align: right;
  font-size: 24px;
  color: #1da8b4;
  font-weight: 600;
}

.losoverflo{
  padding-left: 15px;
  max-height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 150px;
}
.wbody {
  background: #F5F5F5 0% 0% no-repeat padding-box;

  background-size: cover;
  opacity: 1;
  min-height: 100vh;
}

#nav {
  background: #ffffff;
  box-shadow: 0px 12px 24px #00000052;
  opacity: 1;
}
#logo {
  padding-left: 5%;

  
}
#port{
  width: 20%;
}

.carda-title {
  margin-bottom: 5px;
}
.concoc {
  margin-top: 2.5%;
  background-color: #ffffff;
  box-shadow: 0px 12px 24px #00000052;
  width: 360px;
  border-radius: 20px;
  margin-bottom: 60px;
}

#container {
  margin-top: 3%;
  opacity: 1;
  width: 80%;
}

#container #ti {
  text-align: center;
  font: normal normal bold 33px/35px Lato;
  letter-spacing: 0px;
  color: #1da8b4;
  opacity: 1;
}

@media(max-width:400px){
  #container #ti {
    text-align: center;
    font: normal normal bold 10px/25px Lato;
    letter-spacing: 0px;
    color: #1da8b4;
    opacity: 1;
  }
}


#container #subti {
  text-align: center;
  font: normal normal bold 18.5px/27.5px Lato;
  letter-spacing: 0px;
  color: #353535;
  opacity: 1;
}

@media only screen and (max-width: 400px){
  #img-carda{
    width: 40px;
  }

  #container #ti {
    text-align: center;
    font: normal normal bold 10px/15px Lato;
    letter-spacing: 0px;
    color: #1da8b4;
    opacity: 1;
  }
}

#ti4 {
  font: normal normal bold 22px/42px Lato;
  letter-spacing: 0px;
  color: #353535;
  opacity: 1;
}

#grid1 {
  padding-bottom: 5%;
}

#grid1 h4 {
  text-align: center;
}

#carda1 {
  width: 80%;
  height: 13%;
}
.carda-horizontal {
  display: flex;
  flex: 1 1 auto;
}
.carda {
  background-color: transparent;
  border: solid 0px;

  width: 370px;
  margin-bottom: 8px;
}

#img-carda {
  width: 40%;
  display: flex;
  justify-content: center;
  border-radius: 100px;
  padding-top: 5%;
  margin-left: 30%;
}
.carda-body .carda-title {
  text-align: center;
  font: normal normal bold 20px/25px Lato;
  letter-spacing: 0px;
  color: #353535;
  opacity: 1;
 margin-top: 5%;
}
.carda-body #text {
  font: normal normal 300 11.5px/20px Lato;
  letter-spacing: 0px;
  word-spacing: 2px;
  color: #000000;
  font-weight: normal;

  text-align: center;
}
.bord {
  border-top: solid 1px;
  border-color: #353535;
}
#grid2 {
  margin-bottom: 10%;
  
}

#grid2 h4 {
  text-align: left;
  margin-bottom: 10%;
}

#buttonapple {
  background: #000000;
  box-shadow: 0px 5px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  margin-top: 3px;
  margin-bottom: 9px;
  width: 320px;
  height: 39px;
  padding-top: 1%;
  color: #ffffff;
  font: normal normal bold 16px/27.5px Lato;
}

#button2 {
  background: #1977f3;
  box-shadow: 0px 5px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  margin-top: 3px;
  margin-bottom: 9px;
  width: 320px;
  height: 39px;
  padding-top: 1.5%;
  color: #ffffff;
  font: normal normal bold 16px/27.5px Lato;
}

#button3 {
  background: #ffffff;
  box-shadow: 0px 5px 6px #00000029;
  border-radius: 10px;
  margin-top: 3px;
  margin-bottom: 9px;
  opacity: 1;
  width: 320px;
  height: 39px;
  padding-top: 1.5%;
  color: gray;
  font: normal normal bold 16px/27.5px Lato;
}

.imagen {
  margin-left: 5px;
  margin-top: 1px;
  margin-right: 6px;
}
.imagen1 {
  margin-left: 3px;
  margin-bottom: 5px;
  margin-right: 4px;
}

#row1 {
  margin-top: 5%;
}
#input-change
/* navegador */

#nav {
  background: #ffffff;
  box-shadow: 0px 12px 24px #00000052;
  opacity: 1;
}
#logo {
  padding-left: 5%;
}
#mynavbar {
  margin-right: 5%;
}

#mynavbar a {
  font: normal normal bold 5px/10.5px Lato;
  letter-spacing: 0.8px;
  word-spacing: 2px;
  color: #a3a3a3;
  font-weight: normal;
  text-decoration: none;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  
}

#icon {
  margin-right: 6px;
  margin-bottom: 3px;
}

#lin {
  width: 35px;
  height: 1px;
  margin-left: 10px;
}

/* body */
body {
  background-color: #f5f5f5;
}



.container1 #title1 {
  text-align: center;
  font: normal normal bold 27px/18px Lato;
  letter-spacing: 0px;
  color: #747474;
  opacity: 1;
  margin-top: 10%;
}

.container1 #subtitle1 {
  text-align: center;
  font: normal normal 300 18px/27px Lato;
  letter-spacing: 0px;
  color: #747474;
  opacity: 1;
}

.containerx {
  margin-top: 2.5%;
  background-color: #ffffff;
  box-shadow: 0px 12px 24px #00000052;
}

/* carda1 */

#carda1 {
  margin-left: 45px;
  margin-right: 45px;
  margin-bottom: 30px;
  border-radius: 8px;
  width: 400px;
  height: 90px;
  display: flex;
  color: black;
  /* color: #fff;
    box-shadow: inset 0 0 0 1px #ffffff; */
  position: relative;
  background: white;
  border: solid 1px black;
}

.activecarda {
  background: #1da8b4 0% 0% no-repeat padding-box !important;
}

.activecarda .carda-title {
  color: white !important;
}

.activecarda .carda-text {
  color: white !important;
}

#carda1 h5,
p {
  color: black;
}

#carda1 .carda-title {
  font: normal normal bold 17px/26.5px Lato;
  font-weight: 700;
  text-decoration: none;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
}

#carda1 .carda-text {
  font: normal normal bold 14px/26.5px Lato;
  font-weight: 700;
  text-decoration: none;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  text-align: left;
  letter-spacing: 0px;

  opacity: 1;
  margin-top: -10px;
}

#carda1 #carda-t {
  margin-top: 15px;
  margin-left: 10px;
}

#carda:focus {
  border-color: red;
}
.carda-img {
  width: 100px;
  height: 88px;
  border-radius: 7px;
}

#grid1 .subt {
  text-align: center;
  font: normal normal bold 20px/33px Lato;
  letter-spacing: 0px;
  color: #747474;
  opacity: 1;
  margin-top: 40px;
  margin-bottom: 40px;
}

/* carda2 */
#carda2 {
  margin-left: 45px;
  margin-right: 45px;
  margin-bottom: 30px;
  border-radius: 8px;
  width: 400px;
  height: 90px;
  display: flex;
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: solid 1px;
  border-color: #707070;
}
.account-title
#carda2 .carda-title {
  font: normal normal bold 17px/26.5px Lato;
  font-weight: 700;
  text-decoration: none;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  color: #474747;
}

#carda2 .carda-text {
  font: normal normal bold 14px/26.5px Lato;
  font-weight: 700;
  text-decoration: none;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  text-align: left;
  letter-spacing: 0px;
  color: #474747;
  opacity: 1;
  margin-top: -10px;
}

#carda2 #carda-t {
  margin-top: 15px;
  margin-left: 10px;
}

#img2 {
  width: 95px;
  height: 95px;
  margin-top: -12px;
  margin-left: 6px;
}

/* grid2 */

.sp {
  border-left: solid 0.5px;
  border-color: #a3a3a3;
  margin-top: 10px;
  margin-bottom: 10px;
}
#grid2 .subt {
  border-top: solid 2px;
  border-color: #d6d6d6;
  text-align: center;
  font: normal normal bold 20px/33px Lato;
  letter-spacing: 0px;
  color: #747474;
  opacity: 1;
  padding-top: 5%;
  margin-top: 40px;
  margin-bottom: 30px;
}
#ly {
  text-align: center;
  font: normal normal normal 20px/10px Lato;
  letter-spacing: 0px;
  color: #747474;
  opacity: 1;
  
  margin-bottom: 29px;
}
#ly2 {
  text-align: left;
  font: normal normal normal 20px/10px Lato;
  letter-spacing: 0px;
  color: #747474;
  opacity: 1;
  margin-left: 205px;
  margin-bottom: 2px;
}

#checked {
  font: normal normal bold 15px/28px Lato;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #707070;
  opacity: 1;
  width: 50px;
  height: 25px;
  margin-left: 6px;
  padding-left: 4px;
}

#checked option {
  text-align: left;
  font: normal normal bold 12px/28px Lato;
  letter-spacing: 0px;
  color: #747474;
  opacity: 1;
  margin-left: 20px;
}
option:checked {
  box-shadow: 0 0 0 3px lime;
  color: red;
}

.container2 {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #b8b8b8;
  border-radius: 20px;
  opacity: 1;
  margin-left: 17px;
  margin-right: 17px;
  padding-bottom: 3%;
}
#div-price{
  padding-left: 10%;
}
.container2 h6 {
  
  text-align: left;
  font: normal normal bold 15px/28px Lato;
  letter-spacing: 0px;
  color: #747474;
  opacity: 1;
  padding-left: 61px;
  margin-bottom: 10px;
}

#producto {
  text-align: left;
  font: normal normal normal 13px/4px Lato;
  letter-spacing: 0px;
  color: #747474;
  opacity: 1;
  padding-left: 27%;
}

#price {
  text-align: left;
  font: normal normal bold 13px/4px Lato;
  letter-spacing: 0px;
  color: #464646;
  opacity: 1;
}

#summary1 {
  
  padding-left: 25px;
}

.button {
  background: #44cbd8 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  width: 360px;
  height: 40px;
  text-align: center;
  padding-top: 8px;
  color: #ffffff;
  font-weight: normal;
  text-decoration: none;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  
  margin-top: 10px;
  margin-left: 5%;
  
}
.container3 {
  background-color: #ffffff;
  box-shadow: 7px -24px 24px #00000029;
  margin-top: 20px;
  margin-bottom: 20px;
}

#checkbox {
  margin-left: 135px;
  margin-right: 135px;
  color: #747474;
  margin-bottom: 4px;
}

#icon {
  margin-right: 6px;
  margin-bottom: 3px;
}

#lin {
  width: 35px;
  height: 1px;
  margin-left: 10px;
}

/* body */
body {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
}
#carda-email {
  top: 83px;
  left: 35px;
  width: 624px;
  height: 110px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 26px;
  opacity: 1;
}

#carda-form {
  top: 93px;
  left: 35px;
  width: 624px;
  height: 350px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 26px;
  opacity: 1;
  margin-bottom: 105px;
}

#carda-title {
  text-align: left;
  font: normal normal bold 18px Lato;
  letter-spacing: 0px;
  color: #474747;
  opacity: 1;
  margin-top: 10px;
  margin-left: 5px;
}

#carda-text {
  text-align: left;
  font: normal normal normal 12px Lato;
  letter-spacing: 0px;
  color: #747474;
  opacity: 1;
  margin-top: 5px;
  margin-left: 5px;
}

.carda-link {
  position: absolute;
  margin-top: -10px;
  margin-left: 75%;
  text-align: left;
  font: normal normal bold 12px Lato;
  letter-spacing: 0px;
  color: #1da8b4;
  opacity: 1;
}

#input1 {
  width: 275px;
  margin-left: 5px;
  margin-bottom: 5px;
}

#input2 {
  width: 120px;
  margin-left: 5px;
}

#carda-info {
  width: 100%;
  height: auto;
  margin-top: 5%;
  margin-bottom: 5%;
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 8px;
  opacity: 1;
  padding: 5%;
}

.bill {
  margin-top: 20px;
  margin-left: 20px;
  padding-top: 20px;
  width: 87%;
}
.bill2 {
  margin-top: 20px;
  margin-left: 20px;
  padding-top: 20px;
  width: 87%;
  border-top: 0.5px solid;
  border-color: #a3a3a3;
}

.bill-1 {
  text-align: left;
  font: normal normal bold 16px/10px Lato;
  letter-spacing: 0px;
  color: #474747;
  opacity: 1;
}

.bill-2 {
  text-align: left;
  font: normal normal bold 16px/10px Lato;
  letter-spacing: 0px;
  color: #747474;
  opacity: 1;
}

.bill-1a {
  text-align: right;
  font: normal normal bold 16px/10px Lato;
  letter-spacing: 0px;
  color: #474747;
  opacity: 1;
}

.bill-2a {
  text-align: right;
  font: normal normal bold 16px/10px Lato;
  letter-spacing: 0px;
  color: #747474;
  opacity: 1;
}

#carda-title2 {
  text-align: left;
  font: normal normal bold 21px Lato;
  letter-spacing: 0px;
  color: #474747;
  opacity: 1;
  margin-top: 10px;
  margin-left: 20px;
}

/* navegador */

#nav {
  background: #ffffff;
  box-shadow: 0px 12px 24px #00000052;
  opacity: 1;
}
#logo {
  padding-left: 5%;
}
#mynavbar {
  margin-left: 5%;
}

#mynavbar a {
  font: normal normal bold 12px/10.5px Lato;
  letter-spacing: 0.8px;
  word-spacing: 2px;
  color: #a3a3a3;
  font-weight: normal;
  text-decoration: none;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  margin-right: -18px;
}

#icon {
  margin-right: 6px;
  margin-bottom: 3px;
}

#lin {
  width: 35px;
  height: 1px;
  margin-left: 10px;
}

/* body */
body {
  background-color: #f5f5f5;
}

#ti1 {
  margin-top: 40px;
  margin-left: 100px;
  margin-bottom: 20px;
  text-align: left;
  font: normal normal bold 27px/33px Lato;
  letter-spacing: 0px;
  color: #474747;
  opacity: 1;
}

/* checkbox box */
.checkbox {
  margin-left: -18px;
}
.t-check {
  text-align: left;
  font: normal normal bold 15px/30px Lato;
  letter-spacing: 0px;
  color: #1da8b4;
  opacity: 1;
}

li {
  list-style: none;
}

.carda {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 24px #00000029;
  border-radius: 31px;
  opacity: 1;
  margin-bottom: 30px;
}

.carda-img-top {
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
}

.carda-title {
  text-align: center;
  font: normal normal bold 14px/21px Lato;
  letter-spacing: 0px;
  color: #474747;
  opacity: 1;
}

.carda-text {
  text-align: center;
  font: normal normal normal 12px/16px Lato;
  letter-spacing: 0px;
  color: #747474;
  opacity: 1;
}

#btn {
  background: #dddddd 0% 0% no-repeat padding-box;
  border-radius: 2px;
  opacity: 1;
  text-align: center;
  font: normal normal normal 10px/15px Lato;
  letter-spacing: 0px;
  color: #747474;
  opacity: 1;
  width: 50%;
  height: 17px;
  text-decoration: none;
  margin-left: 25%;
  margin-bottom: 20px;
}

#cantidad {
  width: 50px;
  border: transparent;
}

.inventario {
  margin-left: 26%;
}

/* chatbox */
.carda1 {
  position: fixed;
  bottom: 10px;
  right: 0px;
  background-color: #ffffff;
  box-shadow: 0px 12px 24px #00000029;
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
}
#top-chat {
  background: #1da8b4 0% 0% no-repeat padding-box;
  text-align: left;
  font: normal normal bold 30px/36px Lato;
  letter-spacing: 0px;
  color: #ffffff;
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
}

#top-chat .carda-title {
  text-align: left;
  font: normal normal bold 18px/18px Lato;
  letter-spacing: 0px;
  color: #ffffff;
}

#text1 {
  text-align: left;
  font: normal normal bold 14px/11px Lato;
  letter-spacing: 0px;
  color: #747474;
  margin-left: 17px;
  margin-top: 10px;
}
#text2 {
  text-align: left;
  font: normal normal bold 13px/1px Lato;
  letter-spacing: 0px;
  color: #1da8b4;
  margin-left: 17px;
}
#carda-img2 {
  border: none;
  border-radius: 13px;
  width: 90px;
  height: 50px;
}

#button {
  background: #44cbd8 0% 0% no-repeat padding-box;
  border-radius: 22px;
  text-align: center;
  font: normal normal bold 12px/28px Lato;
  letter-spacing: 0px;
  color: #ffffff;
  width: 200px;
  height: 30px;
  margin-top: 10px;
  margin-left: 50px;
  margin-bottom: 10px;
}

.button-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px -3px 6px #00000029;
  opacity: 1;
  margin-top: 40px;
}

#space {
  border: 0.1px solid #dddddd;
  opacity: 1;
  width: 250px;
  height: 0.5px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 12px;
}

#ti3 {
  text-align: left;
  font: normal normal bold 12px/12px Lato;
  letter-spacing: 0px;
  color: #474747;
  opacity: 1;
  margin-top: 10px;
  margin-left: -14px;
}

#inv-chat {
  margin-left: -17px;
}

.minus.icon {
  color: #ffffff;
  position: absolute;
  margin-left: 240px;
  margin-top: 14px;
  width: 18px;
  height: 2px;
  background-color: currentColor;
}

//account pages

#account-card {
  
  border-radius: 10px;
  max-width: 95%;
  height: 100px;
}
#account-card,
.card-title {
  text-align: left;
  font: normal normal bold 17px/20px Lato;
  letter-spacing: 0px;
  color: #474747;
}

#account-card,
.carda-body {
  text-align: left;
  font: normal normal bold 12px/17px Lato;
  letter-spacing: 0px;
  color: #747474;
}
#accoun-img {
  border-radius: 100px;
  background: #44cbd8 0% 0% no-repeat padding-box;
  max-width: 80px;
  max-height: 80px;
  margin-left: 25px;
  margin-top: 2%;
 
}
#img-placeholder {
  margin-top: 16px;
  margin-left: 10px;
  height: 47px;
  width: 37px;
}
#img-placeholder2 {
  margin-top: 18px;
  margin-left: 8px;
  max-width: 40px;
  max-height: 40px;
}

.account-title {
  text-align: left;
  font: normal normal bold 18px/38px Lato;
  letter-spacing: 0px;
  color: #474747;
  opacity: 1;
  margin-left: 5%;

}

// YourOrder

#header-order {
  font: normal normal normal 17px/30px Lato;
  text-align: left;
  letter-spacing: 0px;
  color: #747474;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
#order-title {
  text-align: left;
  font: normal normal bold 14px/12px Lato;
  letter-spacing: 0px;
  color: #474747;
}

#order-date,
#order-text {
  text-align: left;
  font: normal normal normal 13px/8px Lato;
  letter-spacing: 0px;
  color: #474747;
}

#card-order {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 24px #00000029;
  border-radius: 20px;
  width: 100%;
}

#btn-order {
  background: #44cbd8 0% 0% no-repeat padding-box;
  border-color: #44cbd8;
  border-radius: 8px;
  margin-top: 15px;
  width: 25%;
  height: 50%;
  font-size: 12px;
  padding-bottom: 6%;
}
.carda-link
//profile


#btn-profile {
  background: #44cbd8 0% 0% no-repeat padding-box;
  border-color: #44cbd8;
  border-radius: 5px;

  width: 60%;
  height: 10%;
  margin-top: 25%;
  
  text-align: left;
  font: normal normal bold 12px/10px Lato;
  letter-spacing: 0px;
  color: #ffffff;
}

//change name

#btn-changename {
  background: #44cbd8 0% 0% no-repeat padding-box;
  border-color: #44cbd8;
  border-radius: 8px;

  width: 20%;
  height: 25px;
  margin-top: 3%;
  margin-left: 76%;
  text-align: center;
  font: normal normal bold 12px/10px Lato;
  letter-spacing: 0px;
  color: #ffffff;
}
#card-profile {
  width: 100%;
  height: 5%;
 
  background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #a3a3a3;
border-radius: 16px;
  
}

#input-change3 {
  width: 98%;
  margin-left: 5px;
  margin-bottom: 5px;
}


//edit paymend
#input-change {
  width: 100%;
  margin-left: 5px;
  margin-bottom: 5px;
}
#input-change2 {
  width: 100%;
  
}

#card-profile2 {
  width:100%;
 
}

#btn-changename2 {
  background: #44cbd8 0% 0% no-repeat padding-box;
  border-color: #44cbd8;
  border-radius: 10px;

  width: 60%;
  height: 45px;
  margin-top: 8%;
  margin-left: 40%;
  text-align: center;
  font: normal normal bold 12px/10px Lato;
  letter-spacing: 0px;
  color: #ffffff;
}

#buttonapple
//details order

#details-title {
  text-align: left;
  font: normal normal bold 25px/32px Lato;
  letter-spacing: 0px;
  color: #474747;
  padding-left: 20px;
}

#details-date {
  text-align: left;
  font: normal normal bold 16px/26px Lato;
  letter-spacing: 0px;
  color: #747474;
  padding-left: 20px;
}

#btn-details {
  text-align: center;
  font: normal normal bold 15px/20px Lato;
  letter-spacing: 0px;
  color: #ffffff;
  background: #44cbd8 0% 0% no-repeat padding-box;
  border-radius: 30px;
  border-color: #44cbd8;
  width: 100px;
  height: 25px;
  margin-left: 20px;
  padding-bottom: 25px;
}

#btn-details2 {
  text-align: center;
  font: normal normal bold 15px/20px Lato;
  letter-spacing: 0px;
  color: #ffffff;
  background: #44cbd8 0% 0% no-repeat padding-box;
  border-radius: 30px;
  border-color: #44cbd8;
  width: 140px;
  height: 25px;
  margin-left: 160px;
  padding-bottom: 25px;
  margin-top: 70px;
}

#title-mealdetail {
  text-align: left;
  font: normal normal bold 20px/28px Lato;
  letter-spacing: 0px;
  color: #474747;
}

#text-mealdetail {
  text-align: left;
  font: normal normal normal 15px/15px Lato;
  letter-spacing: 0px;
  color: #747474;
}

#card-orderdetail {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 23px;
  margin-top: 40px;
}

#img-details {
  padding: 6px;
  width: 140px;
  height: 140px;
  object-fit: cover;
  border-radius: 23px;
}
#details-info {
  border-radius: 23px;
}


//user info
#card-profile3 {
  width: 100%;

  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
  padding-left: 20px;

  margin-bottom: 3%;
}
.info-button-container{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 2px -14px 14px #00000029;
  opacity: 1;
 text-align: center;
}
#input-infodata {
  width: 97%;
  height: 35px;
  margin-left: 5px;
  margin-bottom: 5px;
}
#input-infodata2 {
  width: 100%;
  height: 35px;

  margin-bottom: 5px;
}

#btn-changename3 {
  background: #44cbd8 0% 0% no-repeat padding-box;
  border-color: #44cbd8;
  border-radius: 10px;

  width: 240px;
  height: 35px;
  margin: 15px;
 
  text-align: center;
  font: normal normal bold 12px/10px Lato;
  letter-spacing: 0px;
  color: #ffffff;
}

#input-address1 {
  width: 95%;
  height: 35px;
  margin-left: 5px;
  margin-bottom: 5px;
}

#input-infodata4 {
  width: 95%;
  height: 35px;
  margin-bottom: 5px;
  
}

#card-address1 {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 30px;
  border-radius: 8px;
  padding-left: 20px;
  padding-right: 20px;
  
  margin-bottom: 20px;
}

// edit addess
#input-address {
  width: 275px;
  height: 35px;
  margin-left: 5px;
  margin-bottom: 5px;
}

#input-infodata3 {
  width: 130px;
  height: 35px;

  margin-bottom: 5px;
}

#card-address {
  width: 100%;
  padding-right: 10px;

  padding-top: 40px;
  padding-bottom: 10px;
  border-radius: 23px;
  padding-left: 8%px;
}
#btn-changeaddress {
  background: #44cbd8 0% 0% no-repeat padding-box;
  border-color: #44cbd8;
  border-radius: 30px;

  width: 100px;
  height: 35px;
  margin-top: 15px;
  margin-left: 475px;
  text-align: center;
  font: normal normal bold 12px/10px Lato;
  letter-spacing: 0px;
  color: #ffffff;
}

#arrow{
  margin-right: 15px; 
  width: 20px; 
  height: 20px;
  padding-bottom: 2px;
}